import { QwidButton } from "components";
import Link from "next/link";

const Custom404 = () => {
  return (
    <div className="flex h-screen w-screen">
      <div className="montserrat m-auto flex w-full max-w-[34rem] flex-col items-center gap-6 text-black">
        <h4 className="text-center text-[1.2rem] font-medium leading-10">
          Oops! seems you found your way to a page that doesn&apos;t exist.
        </h4>

        <Link passHref className="flex w-full max-w-[8rem]" href="/">
          <QwidButton isFull text={'Go home'} />
        </Link>
      </div>
    </div>
  );
};

export default Custom404;
